import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { useIsOfferAvailable, useIsOfferVisible } from '../../utils';
import Button from '../Button';

export const VERFUGBAR_BUTTON = 'verfügbar';
export const OFFNEN_BUTTON = 'offnen';

const OfferCard = ({ item, userStore, handleOpenOffer }) => {
  const theme = useTheme();

  const isOfferAvailable = useIsOfferAvailable(userStore, item);
  const isOfferVisible = useIsOfferVisible(userStore, item);

  const handleButtonClick = useCallback(() => {
    switch (item.firstButton) {
      case VERFUGBAR_BUTTON: {
        return handleOpenOffer(item.offerName);
      }
      case OFFNEN_BUTTON: {
        return window.open(item.link, '_blank').focus();
      }
      default: {
        break;
      }
    }
  }, [item.firstButton, item.offerName, handleOpenOffer, item.link]);

  /**
   * @type {Array<{ title: string, img: string }>}
   */
  const attributes = useMemo(() => {
    const _attributes = [];
    if (item.duration) {
      _attributes.push({
        title: item.duration.text,
        img: item.duration.icon,
      });
    }
    if (item.workshopTypes) {
      _attributes.push(
        ...item.workshopTypes.slice(0, 2).map((workshopType) => ({
          title: workshopType.title,
          img: workshopType.img,
        })),
      );
    }
    return _attributes;
  }, [item.duration, item.workshopTypes]);

  if (!isOfferVisible) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '160px 1fr',
        gridTemplateRows: '200px auto auto',
        gridTemplateAreas: `
        'image description'
        '. attributes'
        '. buttons'`,
        gridColumnGap: 16,
        gridRowGap: 16,
        '> img': {
          height: '100%',
        },
      }}
      key={item.title}
    >
      <Box component="img" src={item.image_small} sx={{ gridArea: 'image' }} />
      <Box
        sx={{
          gridArea: 'description',
          borderBottom: `1px solid ${theme.palette.grey[6]}`,
        }}
      >
        <Typography variant="OfferHeadlineBlue" gutterBottom>
          {item.title}
        </Typography>
        <Typography>{item.text}</Typography>
        {item.text_list && (
          <Typography as={'div'} variant="OfferCardBullets">
            <Box
              component="ul"
              sx={{ paddingLeft: 2, marginTop: 0, marginBottom: 0 }}
            >
              {item.text_list.map((t) => (
                <li key={t}>{t}</li>
              ))}
            </Box>
          </Typography>
        )}
      </Box>
      <Box sx={{ gridArea: 'attributes', display: 'flex', gap: 2 }}>
        {attributes.map((attribute) => (
          <Box key={attribute.title} sx={{ display: 'flex' }}>
            <img src={attribute.img} alt="icon" />
            <Typography
              sx={{ marginLeft: 1, fontSize: '12px' }}
              variant={'smallBoldParagraph'}
            >
              {attribute.title}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          gridArea: 'buttons',
          display: 'flex',
          justifyContent: item.modalInfo?.pdfLink ? 'space-between' : undefined,
        }}
      >
        {item.firstButton && (
          <Button
            disabled={
              item.firstButton === VERFUGBAR_BUTTON && !isOfferAvailable
            }
            onClick={handleButtonClick}
          >
            {(() => {
              switch (item.firstButton) {
                case VERFUGBAR_BUTTON: {
                  return isOfferAvailable ? 'Jetzt anmelden' : 'bald verfügbar';
                }
                case OFFNEN_BUTTON: {
                  return 'JETZT ÖFFNEN';
                }
                default: {
                  return null;
                }
              }
            })()}
          </Button>
        )}
        {item.modalInfo?.pdfLink && (
          <Button color="blue" type="outline" to={item.modalInfo.pdfLink}>
            PDF öffnen
          </Button>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userStore: state.userStore.user,
});

OfferCard.propTypes = {
  item: PropTypes.object.isRequired,
  userStore: PropTypes.object.isRequired,
  handleOpenOffer: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(OfferCard);
